/**
 * useAppConfig()で参照可能な設定値。
 * server,client両方で参照可能。つまりセキュアなものは書いてはいけない。
 * 環境変数で上書されることはない。環境に依存しない設定を書く。
 * 環境に依存するものは.env.{envname}に書いて、useRuntimeConfig()で参照する
 */
export default defineAppConfig({
  facebook: {
    graphApi: {
      version: "v18.0",
    },
    apps: {
      kuma2zen: {
        pageId: "61552781416992",
        scope: "ads_management,business_management,instagram_basic,instagram_content_publish,pages_read_engagement",
      },
    },
  },
  instagram: {
    accounts: {
      kuma2zen: {
        id: "17841403516837311",
      },
    }
  }
})
