// documentがundefinedとか怒られるので、clientでのみimportする
import {Carousel, Toast} from "bootstrap"

export default defineNuxtPlugin((nuxtApp) => {
  // clientでのみuseNuxtApp().$bootstrap.Toastのようにアクセス可能
  nuxtApp.provide('bootstrap', {
    Toast: Toast,
    Carousel: Carousel,
  })
})
