import { default as warmupJKWrd8XA06Meta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/_ah/warmup.vue?macro=true";
import { default as indexT22jZqR3wgMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/about/index.vue?macro=true";
import { default as _91id_93OlIxVbQJWiMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[id].vue?macro=true";
import { default as _91id_938dvXyOHzpkMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[placeId]/posts/[id].vue?macro=true";
import { default as indexVzr2HXO1fYMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[placeId]/posts/index.vue?macro=true";
import { default as newFKiQJ6hBpKMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[placeId]/posts/new.vue?macro=true";
import { default as indexbziLCk2A6gMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/index.vue?macro=true";
import { default as newPGswjfzjsfMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/new.vue?macro=true";
import { default as _91id_93jr6bklXfB3Meta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[id].vue?macro=true";
import { default as indexSZvNjoDLRaMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/index.vue?macro=true";
import { default as newELHYW2J8AOMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/new.vue?macro=true";
import { default as indexbIdYlDaeJgMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/bucketImages/index.vue?macro=true";
import { default as auth9pITrzqB7oMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/facebook/auth.vue?macro=true";
import { default as get_sessionPS903KYJ24Meta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/get_session.vue?macro=true";
import { default as indexM9dmp5gYYOMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/index.vue?macro=true";
import { default as _91id_93Fn9noRgxLfMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/journals/[id].vue?macro=true";
import { default as indexjytIlQ4HrBMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/journals/index.vue?macro=true";
import { default as new5G7dxeGa7pMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/journals/new.vue?macro=true";
import { default as loginxjZp6iQnaEMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/login.vue?macro=true";
import { default as logoutpvyzya4XVDMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/logout.vue?macro=true";
import { default as _91id_93DcTPPBoKeAMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/photoSets/[id].vue?macro=true";
import { default as indexZnXnuMyjKPMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/photoSets/index.vue?macro=true";
import { default as newqbUdkrP1cWMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/photoSets/new.vue?macro=true";
import { default as _91id_93_45previewXu7H5U5epYMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/[id]-preview.vue?macro=true";
import { default as _91id_93bAzcT6s6YVMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/[id].vue?macro=true";
import { default as indexZTIh5vStizMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/index.vue?macro=true";
import { default as newhrILTdFowsMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/new.vue?macro=true";
import { default as _91id_93Efh7I3SBPgMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/postSeries/[id].vue?macro=true";
import { default as index4jOOsc3Ex8Meta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/postSeries/index.vue?macro=true";
import { default as newSf0zXriKOCMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/postSeries/new.vue?macro=true";
import { default as _91id_937r6K0Vy9RGMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/selectableTags/[id].vue?macro=true";
import { default as indext643pZB54xMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/selectableTags/index.vue?macro=true";
import { default as newCCyzvvA13GMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/selectableTags/new.vue?macro=true";
import { default as index9IgUKxopDnMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/index.vue?macro=true";
import { default as _91id_93bwTMnEdALbMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/videos/[id].vue?macro=true";
import { default as indexYAOfrRMyx0Meta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/videos/index.vue?macro=true";
import { default as new9drQC6SMiaMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/videos/new.vue?macro=true";
import { default as indexrFJfj8spE1Meta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/index.vue?macro=true";
import { default as _91id_93pf68gPxkZOMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/places/[id].vue?macro=true";
import { default as _91id_93zmRdOJcGTMMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/places/[placeId]/p/[id].vue?macro=true";
import { default as indexzgRlgVU9cBMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/places/index.vue?macro=true";
import { default as indexLFTgz8NhADMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/index.vue?macro=true";
import { default as indexnYpUom5MSuMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/index.vue?macro=true";
import { default as _91id_93ONK8Wl5CTMMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/journals/[id].vue?macro=true";
import { default as indexOcxbAqf0fHMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/journals/index.vue?macro=true";
import { default as _91id_93SJQHezlYbaMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/p/[id].vue?macro=true";
import { default as indexsS8BxZX3w8Meta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/p/index.vue?macro=true";
import { default as _91id_93bCnn5qOESQMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/redirect/places/[id].vue?macro=true";
import { default as _91id_93FLHxdamUNQMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/series/[id].vue?macro=true";
import { default as indexBw9tBTNh8dMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/series/index.vue?macro=true";
import { default as cookie_policyz56jorFfvYMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/support/cookie_policy.vue?macro=true";
import { default as indexgoDMX1OEDYMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/index.vue?macro=true";
import { default as accessBEbAenNp8jMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/access.vue?macro=true";
import { default as akagijoF8YjNTWpycMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/akagijo.vue?macro=true";
import { default as hananoiwayaKICwpSBzqxMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/hananoiwaya.vue?macro=true";
import { default as indexvyV43yHY5yMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/index.vue?macro=true";
import { default as mapSgpmRXGnx2Meta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/map.vue?macro=true";
import { default as matsumototougeFBWrIG1uafMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/matsumototouge.vue?macro=true";
import { default as onigajoLbUHqJw19MMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/onigajo.vue?macro=true";
import { default as sengyogKDBFNAG1YMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/sengyo.vue?macro=true";
import { default as senmaidaV0hrXZjhHKMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/senmaida.vue?macro=true";
import { default as shichirimihamaxPW9LH7TabMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/shichirimihama.vue?macro=true";
import { default as shishiiwa4VWdONCFd1Meta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/shishiiwa.vue?macro=true";
import { default as yunoguchionsen9BOQC8R72eMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/yunoguchionsen.vue?macro=true";
import { default as hayatamataishaWQoPFJ8aaoMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/hayatamataisha.vue?macro=true";
import { default as indexrr9Ws4EaLpMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/index.vue?macro=true";
import { default as kamikura2uU6OjGoXBMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/kamikura.vue?macro=true";
import { default as kumanoohashiSbxCUBOEpNMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/kumanoohashi.vue?macro=true";
import { default as kushimoto1hthIkH0prMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/kushimoto.vue?macro=true";
import { default as mapQLAu3SCyreMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/map.vue?macro=true";
import { default as indexh1Ht8cwzoKMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/nagano/matsumoto/index.vue?macro=true";
import { default as indexgt2GR5EM6jMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/shikoku/kochi/index.vue?macro=true";
import { default as map9wFUZecgQiMeta } from "/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/shikoku/kochi/map.vue?macro=true";
export default [
  {
    name: warmupJKWrd8XA06Meta?.name ?? "_ah-warmup___ja",
    path: warmupJKWrd8XA06Meta?.path ?? "/_ah/warmup",
    meta: warmupJKWrd8XA06Meta || {},
    alias: warmupJKWrd8XA06Meta?.alias || [],
    redirect: warmupJKWrd8XA06Meta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/_ah/warmup.vue").then(m => m.default || m)
  },
  {
    name: warmupJKWrd8XA06Meta?.name ?? "_ah-warmup___en",
    path: warmupJKWrd8XA06Meta?.path ?? "/en/_ah/warmup",
    meta: warmupJKWrd8XA06Meta || {},
    alias: warmupJKWrd8XA06Meta?.alias || [],
    redirect: warmupJKWrd8XA06Meta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/_ah/warmup.vue").then(m => m.default || m)
  },
  {
    name: indexT22jZqR3wgMeta?.name ?? "about___ja",
    path: indexT22jZqR3wgMeta?.path ?? "/about",
    meta: indexT22jZqR3wgMeta || {},
    alias: indexT22jZqR3wgMeta?.alias || [],
    redirect: indexT22jZqR3wgMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: indexT22jZqR3wgMeta?.name ?? "about___en",
    path: indexT22jZqR3wgMeta?.path ?? "/en/about",
    meta: indexT22jZqR3wgMeta || {},
    alias: indexT22jZqR3wgMeta?.alias || [],
    redirect: indexT22jZqR3wgMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93OlIxVbQJWiMeta?.name ?? "admin-areaMaps-areaMapId-places-id___ja",
    path: _91id_93OlIxVbQJWiMeta?.path ?? "/admin/areaMaps/:areaMapId()/places/:id()",
    meta: _91id_93OlIxVbQJWiMeta || {},
    alias: _91id_93OlIxVbQJWiMeta?.alias || [],
    redirect: _91id_93OlIxVbQJWiMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93OlIxVbQJWiMeta?.name ?? "admin-areaMaps-areaMapId-places-id___en",
    path: _91id_93OlIxVbQJWiMeta?.path ?? "/en/admin/areaMaps/:areaMapId()/places/:id()",
    meta: _91id_93OlIxVbQJWiMeta || {},
    alias: _91id_93OlIxVbQJWiMeta?.alias || [],
    redirect: _91id_93OlIxVbQJWiMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_938dvXyOHzpkMeta?.name ?? "admin-areaMaps-areaMapId-places-placeId-posts-id___ja",
    path: _91id_938dvXyOHzpkMeta?.path ?? "/admin/areaMaps/:areaMapId()/places/:placeId()/posts/:id()",
    meta: _91id_938dvXyOHzpkMeta || {},
    alias: _91id_938dvXyOHzpkMeta?.alias || [],
    redirect: _91id_938dvXyOHzpkMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[placeId]/posts/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_938dvXyOHzpkMeta?.name ?? "admin-areaMaps-areaMapId-places-placeId-posts-id___en",
    path: _91id_938dvXyOHzpkMeta?.path ?? "/en/admin/areaMaps/:areaMapId()/places/:placeId()/posts/:id()",
    meta: _91id_938dvXyOHzpkMeta || {},
    alias: _91id_938dvXyOHzpkMeta?.alias || [],
    redirect: _91id_938dvXyOHzpkMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[placeId]/posts/[id].vue").then(m => m.default || m)
  },
  {
    name: indexVzr2HXO1fYMeta?.name ?? "admin-areaMaps-areaMapId-places-placeId-posts___ja",
    path: indexVzr2HXO1fYMeta?.path ?? "/admin/areaMaps/:areaMapId()/places/:placeId()/posts",
    meta: indexVzr2HXO1fYMeta || {},
    alias: indexVzr2HXO1fYMeta?.alias || [],
    redirect: indexVzr2HXO1fYMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[placeId]/posts/index.vue").then(m => m.default || m)
  },
  {
    name: indexVzr2HXO1fYMeta?.name ?? "admin-areaMaps-areaMapId-places-placeId-posts___en",
    path: indexVzr2HXO1fYMeta?.path ?? "/en/admin/areaMaps/:areaMapId()/places/:placeId()/posts",
    meta: indexVzr2HXO1fYMeta || {},
    alias: indexVzr2HXO1fYMeta?.alias || [],
    redirect: indexVzr2HXO1fYMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[placeId]/posts/index.vue").then(m => m.default || m)
  },
  {
    name: newFKiQJ6hBpKMeta?.name ?? "admin-areaMaps-areaMapId-places-placeId-posts-new___ja",
    path: newFKiQJ6hBpKMeta?.path ?? "/admin/areaMaps/:areaMapId()/places/:placeId()/posts/new",
    meta: newFKiQJ6hBpKMeta || {},
    alias: newFKiQJ6hBpKMeta?.alias || [],
    redirect: newFKiQJ6hBpKMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[placeId]/posts/new.vue").then(m => m.default || m)
  },
  {
    name: newFKiQJ6hBpKMeta?.name ?? "admin-areaMaps-areaMapId-places-placeId-posts-new___en",
    path: newFKiQJ6hBpKMeta?.path ?? "/en/admin/areaMaps/:areaMapId()/places/:placeId()/posts/new",
    meta: newFKiQJ6hBpKMeta || {},
    alias: newFKiQJ6hBpKMeta?.alias || [],
    redirect: newFKiQJ6hBpKMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/[placeId]/posts/new.vue").then(m => m.default || m)
  },
  {
    name: indexbziLCk2A6gMeta?.name ?? "admin-areaMaps-areaMapId-places___ja",
    path: indexbziLCk2A6gMeta?.path ?? "/admin/areaMaps/:areaMapId()/places",
    meta: indexbziLCk2A6gMeta || {},
    alias: indexbziLCk2A6gMeta?.alias || [],
    redirect: indexbziLCk2A6gMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/index.vue").then(m => m.default || m)
  },
  {
    name: indexbziLCk2A6gMeta?.name ?? "admin-areaMaps-areaMapId-places___en",
    path: indexbziLCk2A6gMeta?.path ?? "/en/admin/areaMaps/:areaMapId()/places",
    meta: indexbziLCk2A6gMeta || {},
    alias: indexbziLCk2A6gMeta?.alias || [],
    redirect: indexbziLCk2A6gMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/index.vue").then(m => m.default || m)
  },
  {
    name: newPGswjfzjsfMeta?.name ?? "admin-areaMaps-areaMapId-places-new___ja",
    path: newPGswjfzjsfMeta?.path ?? "/admin/areaMaps/:areaMapId()/places/new",
    meta: newPGswjfzjsfMeta || {},
    alias: newPGswjfzjsfMeta?.alias || [],
    redirect: newPGswjfzjsfMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/new.vue").then(m => m.default || m)
  },
  {
    name: newPGswjfzjsfMeta?.name ?? "admin-areaMaps-areaMapId-places-new___en",
    path: newPGswjfzjsfMeta?.path ?? "/en/admin/areaMaps/:areaMapId()/places/new",
    meta: newPGswjfzjsfMeta || {},
    alias: newPGswjfzjsfMeta?.alias || [],
    redirect: newPGswjfzjsfMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[areaMapId]/places/new.vue").then(m => m.default || m)
  },
  {
    name: _91id_93jr6bklXfB3Meta?.name ?? "admin-areaMaps-id___ja",
    path: _91id_93jr6bklXfB3Meta?.path ?? "/admin/areaMaps/:id()",
    meta: _91id_93jr6bklXfB3Meta || {},
    alias: _91id_93jr6bklXfB3Meta?.alias || [],
    redirect: _91id_93jr6bklXfB3Meta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93jr6bklXfB3Meta?.name ?? "admin-areaMaps-id___en",
    path: _91id_93jr6bklXfB3Meta?.path ?? "/en/admin/areaMaps/:id()",
    meta: _91id_93jr6bklXfB3Meta || {},
    alias: _91id_93jr6bklXfB3Meta?.alias || [],
    redirect: _91id_93jr6bklXfB3Meta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/[id].vue").then(m => m.default || m)
  },
  {
    name: indexSZvNjoDLRaMeta?.name ?? "admin-areaMaps___ja",
    path: indexSZvNjoDLRaMeta?.path ?? "/admin/areaMaps",
    meta: indexSZvNjoDLRaMeta || {},
    alias: indexSZvNjoDLRaMeta?.alias || [],
    redirect: indexSZvNjoDLRaMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/index.vue").then(m => m.default || m)
  },
  {
    name: indexSZvNjoDLRaMeta?.name ?? "admin-areaMaps___en",
    path: indexSZvNjoDLRaMeta?.path ?? "/en/admin/areaMaps",
    meta: indexSZvNjoDLRaMeta || {},
    alias: indexSZvNjoDLRaMeta?.alias || [],
    redirect: indexSZvNjoDLRaMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/index.vue").then(m => m.default || m)
  },
  {
    name: newELHYW2J8AOMeta?.name ?? "admin-areaMaps-new___ja",
    path: newELHYW2J8AOMeta?.path ?? "/admin/areaMaps/new",
    meta: newELHYW2J8AOMeta || {},
    alias: newELHYW2J8AOMeta?.alias || [],
    redirect: newELHYW2J8AOMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/new.vue").then(m => m.default || m)
  },
  {
    name: newELHYW2J8AOMeta?.name ?? "admin-areaMaps-new___en",
    path: newELHYW2J8AOMeta?.path ?? "/en/admin/areaMaps/new",
    meta: newELHYW2J8AOMeta || {},
    alias: newELHYW2J8AOMeta?.alias || [],
    redirect: newELHYW2J8AOMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/areaMaps/new.vue").then(m => m.default || m)
  },
  {
    name: indexbIdYlDaeJgMeta?.name ?? "admin-bucketImages___ja",
    path: indexbIdYlDaeJgMeta?.path ?? "/admin/bucketImages",
    meta: indexbIdYlDaeJgMeta || {},
    alias: indexbIdYlDaeJgMeta?.alias || [],
    redirect: indexbIdYlDaeJgMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/bucketImages/index.vue").then(m => m.default || m)
  },
  {
    name: indexbIdYlDaeJgMeta?.name ?? "admin-bucketImages___en",
    path: indexbIdYlDaeJgMeta?.path ?? "/en/admin/bucketImages",
    meta: indexbIdYlDaeJgMeta || {},
    alias: indexbIdYlDaeJgMeta?.alias || [],
    redirect: indexbIdYlDaeJgMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/bucketImages/index.vue").then(m => m.default || m)
  },
  {
    name: auth9pITrzqB7oMeta?.name ?? "admin-facebook-auth___ja",
    path: auth9pITrzqB7oMeta?.path ?? "/admin/facebook/auth",
    meta: auth9pITrzqB7oMeta || {},
    alias: auth9pITrzqB7oMeta?.alias || [],
    redirect: auth9pITrzqB7oMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/facebook/auth.vue").then(m => m.default || m)
  },
  {
    name: auth9pITrzqB7oMeta?.name ?? "admin-facebook-auth___en",
    path: auth9pITrzqB7oMeta?.path ?? "/en/admin/facebook/auth",
    meta: auth9pITrzqB7oMeta || {},
    alias: auth9pITrzqB7oMeta?.alias || [],
    redirect: auth9pITrzqB7oMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/facebook/auth.vue").then(m => m.default || m)
  },
  {
    name: get_sessionPS903KYJ24Meta?.name ?? "admin-get_session___ja",
    path: get_sessionPS903KYJ24Meta?.path ?? "/admin/get_session",
    meta: get_sessionPS903KYJ24Meta || {},
    alias: get_sessionPS903KYJ24Meta?.alias || [],
    redirect: get_sessionPS903KYJ24Meta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/get_session.vue").then(m => m.default || m)
  },
  {
    name: get_sessionPS903KYJ24Meta?.name ?? "admin-get_session___en",
    path: get_sessionPS903KYJ24Meta?.path ?? "/en/admin/get_session",
    meta: get_sessionPS903KYJ24Meta || {},
    alias: get_sessionPS903KYJ24Meta?.alias || [],
    redirect: get_sessionPS903KYJ24Meta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/get_session.vue").then(m => m.default || m)
  },
  {
    name: indexM9dmp5gYYOMeta?.name ?? "admin___ja",
    path: indexM9dmp5gYYOMeta?.path ?? "/admin",
    meta: indexM9dmp5gYYOMeta || {},
    alias: indexM9dmp5gYYOMeta?.alias || [],
    redirect: indexM9dmp5gYYOMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: indexM9dmp5gYYOMeta?.name ?? "admin___en",
    path: indexM9dmp5gYYOMeta?.path ?? "/en/admin",
    meta: indexM9dmp5gYYOMeta || {},
    alias: indexM9dmp5gYYOMeta?.alias || [],
    redirect: indexM9dmp5gYYOMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Fn9noRgxLfMeta?.name ?? "admin-journals-id___ja",
    path: _91id_93Fn9noRgxLfMeta?.path ?? "/admin/journals/:id()",
    meta: _91id_93Fn9noRgxLfMeta || {},
    alias: _91id_93Fn9noRgxLfMeta?.alias || [],
    redirect: _91id_93Fn9noRgxLfMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/journals/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Fn9noRgxLfMeta?.name ?? "admin-journals-id___en",
    path: _91id_93Fn9noRgxLfMeta?.path ?? "/en/admin/journals/:id()",
    meta: _91id_93Fn9noRgxLfMeta || {},
    alias: _91id_93Fn9noRgxLfMeta?.alias || [],
    redirect: _91id_93Fn9noRgxLfMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/journals/[id].vue").then(m => m.default || m)
  },
  {
    name: indexjytIlQ4HrBMeta?.name ?? "admin-journals___ja",
    path: indexjytIlQ4HrBMeta?.path ?? "/admin/journals",
    meta: indexjytIlQ4HrBMeta || {},
    alias: indexjytIlQ4HrBMeta?.alias || [],
    redirect: indexjytIlQ4HrBMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/journals/index.vue").then(m => m.default || m)
  },
  {
    name: indexjytIlQ4HrBMeta?.name ?? "admin-journals___en",
    path: indexjytIlQ4HrBMeta?.path ?? "/en/admin/journals",
    meta: indexjytIlQ4HrBMeta || {},
    alias: indexjytIlQ4HrBMeta?.alias || [],
    redirect: indexjytIlQ4HrBMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/journals/index.vue").then(m => m.default || m)
  },
  {
    name: new5G7dxeGa7pMeta?.name ?? "admin-journals-new___ja",
    path: new5G7dxeGa7pMeta?.path ?? "/admin/journals/new",
    meta: new5G7dxeGa7pMeta || {},
    alias: new5G7dxeGa7pMeta?.alias || [],
    redirect: new5G7dxeGa7pMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/journals/new.vue").then(m => m.default || m)
  },
  {
    name: new5G7dxeGa7pMeta?.name ?? "admin-journals-new___en",
    path: new5G7dxeGa7pMeta?.path ?? "/en/admin/journals/new",
    meta: new5G7dxeGa7pMeta || {},
    alias: new5G7dxeGa7pMeta?.alias || [],
    redirect: new5G7dxeGa7pMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/journals/new.vue").then(m => m.default || m)
  },
  {
    name: loginxjZp6iQnaEMeta?.name ?? "admin-login___ja",
    path: loginxjZp6iQnaEMeta?.path ?? "/admin/login",
    meta: loginxjZp6iQnaEMeta || {},
    alias: loginxjZp6iQnaEMeta?.alias || [],
    redirect: loginxjZp6iQnaEMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: loginxjZp6iQnaEMeta?.name ?? "admin-login___en",
    path: loginxjZp6iQnaEMeta?.path ?? "/en/admin/login",
    meta: loginxjZp6iQnaEMeta || {},
    alias: loginxjZp6iQnaEMeta?.alias || [],
    redirect: loginxjZp6iQnaEMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/login.vue").then(m => m.default || m)
  },
  {
    name: logoutpvyzya4XVDMeta?.name ?? "admin-logout___ja",
    path: logoutpvyzya4XVDMeta?.path ?? "/admin/logout",
    meta: logoutpvyzya4XVDMeta || {},
    alias: logoutpvyzya4XVDMeta?.alias || [],
    redirect: logoutpvyzya4XVDMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutpvyzya4XVDMeta?.name ?? "admin-logout___en",
    path: logoutpvyzya4XVDMeta?.path ?? "/en/admin/logout",
    meta: logoutpvyzya4XVDMeta || {},
    alias: logoutpvyzya4XVDMeta?.alias || [],
    redirect: logoutpvyzya4XVDMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/logout.vue").then(m => m.default || m)
  },
  {
    name: _91id_93DcTPPBoKeAMeta?.name ?? "admin-photoSets-id___ja",
    path: _91id_93DcTPPBoKeAMeta?.path ?? "/admin/photoSets/:id()",
    meta: _91id_93DcTPPBoKeAMeta || {},
    alias: _91id_93DcTPPBoKeAMeta?.alias || [],
    redirect: _91id_93DcTPPBoKeAMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/photoSets/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93DcTPPBoKeAMeta?.name ?? "admin-photoSets-id___en",
    path: _91id_93DcTPPBoKeAMeta?.path ?? "/en/admin/photoSets/:id()",
    meta: _91id_93DcTPPBoKeAMeta || {},
    alias: _91id_93DcTPPBoKeAMeta?.alias || [],
    redirect: _91id_93DcTPPBoKeAMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/photoSets/[id].vue").then(m => m.default || m)
  },
  {
    name: indexZnXnuMyjKPMeta?.name ?? "admin-photoSets___ja",
    path: indexZnXnuMyjKPMeta?.path ?? "/admin/photoSets",
    meta: indexZnXnuMyjKPMeta || {},
    alias: indexZnXnuMyjKPMeta?.alias || [],
    redirect: indexZnXnuMyjKPMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/photoSets/index.vue").then(m => m.default || m)
  },
  {
    name: indexZnXnuMyjKPMeta?.name ?? "admin-photoSets___en",
    path: indexZnXnuMyjKPMeta?.path ?? "/en/admin/photoSets",
    meta: indexZnXnuMyjKPMeta || {},
    alias: indexZnXnuMyjKPMeta?.alias || [],
    redirect: indexZnXnuMyjKPMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/photoSets/index.vue").then(m => m.default || m)
  },
  {
    name: newqbUdkrP1cWMeta?.name ?? "admin-photoSets-new___ja",
    path: newqbUdkrP1cWMeta?.path ?? "/admin/photoSets/new",
    meta: newqbUdkrP1cWMeta || {},
    alias: newqbUdkrP1cWMeta?.alias || [],
    redirect: newqbUdkrP1cWMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/photoSets/new.vue").then(m => m.default || m)
  },
  {
    name: newqbUdkrP1cWMeta?.name ?? "admin-photoSets-new___en",
    path: newqbUdkrP1cWMeta?.path ?? "/en/admin/photoSets/new",
    meta: newqbUdkrP1cWMeta || {},
    alias: newqbUdkrP1cWMeta?.alias || [],
    redirect: newqbUdkrP1cWMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/photoSets/new.vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45previewXu7H5U5epYMeta?.name ?? "admin-posts-id-preview___ja",
    path: _91id_93_45previewXu7H5U5epYMeta?.path ?? "/admin/posts/:id()-preview",
    meta: _91id_93_45previewXu7H5U5epYMeta || {},
    alias: _91id_93_45previewXu7H5U5epYMeta?.alias || [],
    redirect: _91id_93_45previewXu7H5U5epYMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/[id]-preview.vue").then(m => m.default || m)
  },
  {
    name: _91id_93_45previewXu7H5U5epYMeta?.name ?? "admin-posts-id-preview___en",
    path: _91id_93_45previewXu7H5U5epYMeta?.path ?? "/en/admin/posts/:id()-preview",
    meta: _91id_93_45previewXu7H5U5epYMeta || {},
    alias: _91id_93_45previewXu7H5U5epYMeta?.alias || [],
    redirect: _91id_93_45previewXu7H5U5epYMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/[id]-preview.vue").then(m => m.default || m)
  },
  {
    name: _91id_93bAzcT6s6YVMeta?.name ?? "admin-posts-id___ja",
    path: _91id_93bAzcT6s6YVMeta?.path ?? "/admin/posts/:id()",
    meta: _91id_93bAzcT6s6YVMeta || {},
    alias: _91id_93bAzcT6s6YVMeta?.alias || [],
    redirect: _91id_93bAzcT6s6YVMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93bAzcT6s6YVMeta?.name ?? "admin-posts-id___en",
    path: _91id_93bAzcT6s6YVMeta?.path ?? "/en/admin/posts/:id()",
    meta: _91id_93bAzcT6s6YVMeta || {},
    alias: _91id_93bAzcT6s6YVMeta?.alias || [],
    redirect: _91id_93bAzcT6s6YVMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/[id].vue").then(m => m.default || m)
  },
  {
    name: indexZTIh5vStizMeta?.name ?? "admin-posts___ja",
    path: indexZTIh5vStizMeta?.path ?? "/admin/posts",
    meta: indexZTIh5vStizMeta || {},
    alias: indexZTIh5vStizMeta?.alias || [],
    redirect: indexZTIh5vStizMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/index.vue").then(m => m.default || m)
  },
  {
    name: indexZTIh5vStizMeta?.name ?? "admin-posts___en",
    path: indexZTIh5vStizMeta?.path ?? "/en/admin/posts",
    meta: indexZTIh5vStizMeta || {},
    alias: indexZTIh5vStizMeta?.alias || [],
    redirect: indexZTIh5vStizMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/index.vue").then(m => m.default || m)
  },
  {
    name: newhrILTdFowsMeta?.name ?? "admin-posts-new___ja",
    path: newhrILTdFowsMeta?.path ?? "/admin/posts/new",
    meta: newhrILTdFowsMeta || {},
    alias: newhrILTdFowsMeta?.alias || [],
    redirect: newhrILTdFowsMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/new.vue").then(m => m.default || m)
  },
  {
    name: newhrILTdFowsMeta?.name ?? "admin-posts-new___en",
    path: newhrILTdFowsMeta?.path ?? "/en/admin/posts/new",
    meta: newhrILTdFowsMeta || {},
    alias: newhrILTdFowsMeta?.alias || [],
    redirect: newhrILTdFowsMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/posts/new.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Efh7I3SBPgMeta?.name ?? "admin-postSeries-id___ja",
    path: _91id_93Efh7I3SBPgMeta?.path ?? "/admin/postSeries/:id()",
    meta: _91id_93Efh7I3SBPgMeta || {},
    alias: _91id_93Efh7I3SBPgMeta?.alias || [],
    redirect: _91id_93Efh7I3SBPgMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/postSeries/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Efh7I3SBPgMeta?.name ?? "admin-postSeries-id___en",
    path: _91id_93Efh7I3SBPgMeta?.path ?? "/en/admin/postSeries/:id()",
    meta: _91id_93Efh7I3SBPgMeta || {},
    alias: _91id_93Efh7I3SBPgMeta?.alias || [],
    redirect: _91id_93Efh7I3SBPgMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/postSeries/[id].vue").then(m => m.default || m)
  },
  {
    name: index4jOOsc3Ex8Meta?.name ?? "admin-postSeries___ja",
    path: index4jOOsc3Ex8Meta?.path ?? "/admin/postSeries",
    meta: index4jOOsc3Ex8Meta || {},
    alias: index4jOOsc3Ex8Meta?.alias || [],
    redirect: index4jOOsc3Ex8Meta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/postSeries/index.vue").then(m => m.default || m)
  },
  {
    name: index4jOOsc3Ex8Meta?.name ?? "admin-postSeries___en",
    path: index4jOOsc3Ex8Meta?.path ?? "/en/admin/postSeries",
    meta: index4jOOsc3Ex8Meta || {},
    alias: index4jOOsc3Ex8Meta?.alias || [],
    redirect: index4jOOsc3Ex8Meta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/postSeries/index.vue").then(m => m.default || m)
  },
  {
    name: newSf0zXriKOCMeta?.name ?? "admin-postSeries-new___ja",
    path: newSf0zXriKOCMeta?.path ?? "/admin/postSeries/new",
    meta: newSf0zXriKOCMeta || {},
    alias: newSf0zXriKOCMeta?.alias || [],
    redirect: newSf0zXriKOCMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/postSeries/new.vue").then(m => m.default || m)
  },
  {
    name: newSf0zXriKOCMeta?.name ?? "admin-postSeries-new___en",
    path: newSf0zXriKOCMeta?.path ?? "/en/admin/postSeries/new",
    meta: newSf0zXriKOCMeta || {},
    alias: newSf0zXriKOCMeta?.alias || [],
    redirect: newSf0zXriKOCMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/postSeries/new.vue").then(m => m.default || m)
  },
  {
    name: _91id_937r6K0Vy9RGMeta?.name ?? "admin-selectableTags-id___ja",
    path: _91id_937r6K0Vy9RGMeta?.path ?? "/admin/selectableTags/:id()",
    meta: _91id_937r6K0Vy9RGMeta || {},
    alias: _91id_937r6K0Vy9RGMeta?.alias || [],
    redirect: _91id_937r6K0Vy9RGMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/selectableTags/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_937r6K0Vy9RGMeta?.name ?? "admin-selectableTags-id___en",
    path: _91id_937r6K0Vy9RGMeta?.path ?? "/en/admin/selectableTags/:id()",
    meta: _91id_937r6K0Vy9RGMeta || {},
    alias: _91id_937r6K0Vy9RGMeta?.alias || [],
    redirect: _91id_937r6K0Vy9RGMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/selectableTags/[id].vue").then(m => m.default || m)
  },
  {
    name: indext643pZB54xMeta?.name ?? "admin-selectableTags___ja",
    path: indext643pZB54xMeta?.path ?? "/admin/selectableTags",
    meta: indext643pZB54xMeta || {},
    alias: indext643pZB54xMeta?.alias || [],
    redirect: indext643pZB54xMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/selectableTags/index.vue").then(m => m.default || m)
  },
  {
    name: indext643pZB54xMeta?.name ?? "admin-selectableTags___en",
    path: indext643pZB54xMeta?.path ?? "/en/admin/selectableTags",
    meta: indext643pZB54xMeta || {},
    alias: indext643pZB54xMeta?.alias || [],
    redirect: indext643pZB54xMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/selectableTags/index.vue").then(m => m.default || m)
  },
  {
    name: newCCyzvvA13GMeta?.name ?? "admin-selectableTags-new___ja",
    path: newCCyzvvA13GMeta?.path ?? "/admin/selectableTags/new",
    meta: newCCyzvvA13GMeta || {},
    alias: newCCyzvvA13GMeta?.alias || [],
    redirect: newCCyzvvA13GMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/selectableTags/new.vue").then(m => m.default || m)
  },
  {
    name: newCCyzvvA13GMeta?.name ?? "admin-selectableTags-new___en",
    path: newCCyzvvA13GMeta?.path ?? "/en/admin/selectableTags/new",
    meta: newCCyzvvA13GMeta || {},
    alias: newCCyzvvA13GMeta?.alias || [],
    redirect: newCCyzvvA13GMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/selectableTags/new.vue").then(m => m.default || m)
  },
  {
    name: index9IgUKxopDnMeta?.name ?? "admin-youtube___ja",
    path: index9IgUKxopDnMeta?.path ?? "/admin/youtube",
    meta: index9IgUKxopDnMeta || {},
    alias: index9IgUKxopDnMeta?.alias || [],
    redirect: index9IgUKxopDnMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/index.vue").then(m => m.default || m)
  },
  {
    name: index9IgUKxopDnMeta?.name ?? "admin-youtube___en",
    path: index9IgUKxopDnMeta?.path ?? "/en/admin/youtube",
    meta: index9IgUKxopDnMeta || {},
    alias: index9IgUKxopDnMeta?.alias || [],
    redirect: index9IgUKxopDnMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93bwTMnEdALbMeta?.name ?? "admin-youtube-videos-id___ja",
    path: _91id_93bwTMnEdALbMeta?.path ?? "/admin/youtube/videos/:id()",
    meta: _91id_93bwTMnEdALbMeta || {},
    alias: _91id_93bwTMnEdALbMeta?.alias || [],
    redirect: _91id_93bwTMnEdALbMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/videos/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93bwTMnEdALbMeta?.name ?? "admin-youtube-videos-id___en",
    path: _91id_93bwTMnEdALbMeta?.path ?? "/en/admin/youtube/videos/:id()",
    meta: _91id_93bwTMnEdALbMeta || {},
    alias: _91id_93bwTMnEdALbMeta?.alias || [],
    redirect: _91id_93bwTMnEdALbMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/videos/[id].vue").then(m => m.default || m)
  },
  {
    name: indexYAOfrRMyx0Meta?.name ?? "admin-youtube-videos___ja",
    path: indexYAOfrRMyx0Meta?.path ?? "/admin/youtube/videos",
    meta: indexYAOfrRMyx0Meta || {},
    alias: indexYAOfrRMyx0Meta?.alias || [],
    redirect: indexYAOfrRMyx0Meta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/videos/index.vue").then(m => m.default || m)
  },
  {
    name: indexYAOfrRMyx0Meta?.name ?? "admin-youtube-videos___en",
    path: indexYAOfrRMyx0Meta?.path ?? "/en/admin/youtube/videos",
    meta: indexYAOfrRMyx0Meta || {},
    alias: indexYAOfrRMyx0Meta?.alias || [],
    redirect: indexYAOfrRMyx0Meta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/videos/index.vue").then(m => m.default || m)
  },
  {
    name: new9drQC6SMiaMeta?.name ?? "admin-youtube-videos-new___ja",
    path: new9drQC6SMiaMeta?.path ?? "/admin/youtube/videos/new",
    meta: new9drQC6SMiaMeta || {},
    alias: new9drQC6SMiaMeta?.alias || [],
    redirect: new9drQC6SMiaMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/videos/new.vue").then(m => m.default || m)
  },
  {
    name: new9drQC6SMiaMeta?.name ?? "admin-youtube-videos-new___en",
    path: new9drQC6SMiaMeta?.path ?? "/en/admin/youtube/videos/new",
    meta: new9drQC6SMiaMeta || {},
    alias: new9drQC6SMiaMeta?.alias || [],
    redirect: new9drQC6SMiaMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/admin/youtube/videos/new.vue").then(m => m.default || m)
  },
  {
    name: indexrFJfj8spE1Meta?.name ?? "areamaps-areaMapId___ja",
    path: indexrFJfj8spE1Meta?.path ?? "/areamaps/:areaMapId()",
    meta: indexrFJfj8spE1Meta || {},
    alias: indexrFJfj8spE1Meta?.alias || [],
    redirect: indexrFJfj8spE1Meta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/index.vue").then(m => m.default || m)
  },
  {
    name: indexrFJfj8spE1Meta?.name ?? "areamaps-areaMapId___en",
    path: indexrFJfj8spE1Meta?.path ?? "/en/areamaps/:areaMapId()",
    meta: indexrFJfj8spE1Meta || {},
    alias: indexrFJfj8spE1Meta?.alias || [],
    redirect: indexrFJfj8spE1Meta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93pf68gPxkZOMeta?.name ?? "areamaps-areaMapId-places-id___ja",
    path: _91id_93pf68gPxkZOMeta?.path ?? "/areamaps/:areaMapId()/places/:id()",
    meta: _91id_93pf68gPxkZOMeta || {},
    alias: _91id_93pf68gPxkZOMeta?.alias || [],
    redirect: _91id_93pf68gPxkZOMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/places/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93pf68gPxkZOMeta?.name ?? "areamaps-areaMapId-places-id___en",
    path: _91id_93pf68gPxkZOMeta?.path ?? "/en/areamaps/:areaMapId()/places/:id()",
    meta: _91id_93pf68gPxkZOMeta || {},
    alias: _91id_93pf68gPxkZOMeta?.alias || [],
    redirect: _91id_93pf68gPxkZOMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/places/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93zmRdOJcGTMMeta?.name ?? "areamaps-areaMapId-places-placeId-p-id___ja",
    path: _91id_93zmRdOJcGTMMeta?.path ?? "/areamaps/:areaMapId()/places/:placeId()/p/:id()",
    meta: _91id_93zmRdOJcGTMMeta || {},
    alias: _91id_93zmRdOJcGTMMeta?.alias || [],
    redirect: _91id_93zmRdOJcGTMMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/places/[placeId]/p/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93zmRdOJcGTMMeta?.name ?? "areamaps-areaMapId-places-placeId-p-id___en",
    path: _91id_93zmRdOJcGTMMeta?.path ?? "/en/areamaps/:areaMapId()/places/:placeId()/p/:id()",
    meta: _91id_93zmRdOJcGTMMeta || {},
    alias: _91id_93zmRdOJcGTMMeta?.alias || [],
    redirect: _91id_93zmRdOJcGTMMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/places/[placeId]/p/[id].vue").then(m => m.default || m)
  },
  {
    name: indexzgRlgVU9cBMeta?.name ?? "areamaps-areaMapId-places___ja",
    path: indexzgRlgVU9cBMeta?.path ?? "/areamaps/:areaMapId()/places",
    meta: indexzgRlgVU9cBMeta || {},
    alias: indexzgRlgVU9cBMeta?.alias || [],
    redirect: indexzgRlgVU9cBMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/places/index.vue").then(m => m.default || m)
  },
  {
    name: indexzgRlgVU9cBMeta?.name ?? "areamaps-areaMapId-places___en",
    path: indexzgRlgVU9cBMeta?.path ?? "/en/areamaps/:areaMapId()/places",
    meta: indexzgRlgVU9cBMeta || {},
    alias: indexzgRlgVU9cBMeta?.alias || [],
    redirect: indexzgRlgVU9cBMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/[areaMapId]/places/index.vue").then(m => m.default || m)
  },
  {
    name: indexLFTgz8NhADMeta?.name ?? "areamaps___ja",
    path: indexLFTgz8NhADMeta?.path ?? "/areamaps",
    meta: indexLFTgz8NhADMeta || {},
    alias: indexLFTgz8NhADMeta?.alias || [],
    redirect: indexLFTgz8NhADMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/index.vue").then(m => m.default || m)
  },
  {
    name: indexLFTgz8NhADMeta?.name ?? "areamaps___en",
    path: indexLFTgz8NhADMeta?.path ?? "/en/areamaps",
    meta: indexLFTgz8NhADMeta || {},
    alias: indexLFTgz8NhADMeta?.alias || [],
    redirect: indexLFTgz8NhADMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/areamaps/index.vue").then(m => m.default || m)
  },
  {
    name: indexnYpUom5MSuMeta?.name ?? "index___ja",
    path: indexnYpUom5MSuMeta?.path ?? "/",
    meta: indexnYpUom5MSuMeta || {},
    alias: indexnYpUom5MSuMeta?.alias || [],
    redirect: indexnYpUom5MSuMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexnYpUom5MSuMeta?.name ?? "index___en",
    path: indexnYpUom5MSuMeta?.path ?? "/en",
    meta: indexnYpUom5MSuMeta || {},
    alias: indexnYpUom5MSuMeta?.alias || [],
    redirect: indexnYpUom5MSuMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ONK8Wl5CTMMeta?.name ?? "journals-id___ja",
    path: _91id_93ONK8Wl5CTMMeta?.path ?? "/journals/:id()",
    meta: _91id_93ONK8Wl5CTMMeta || {},
    alias: _91id_93ONK8Wl5CTMMeta?.alias || [],
    redirect: _91id_93ONK8Wl5CTMMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/journals/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93ONK8Wl5CTMMeta?.name ?? "journals-id___en",
    path: _91id_93ONK8Wl5CTMMeta?.path ?? "/en/journals/:id()",
    meta: _91id_93ONK8Wl5CTMMeta || {},
    alias: _91id_93ONK8Wl5CTMMeta?.alias || [],
    redirect: _91id_93ONK8Wl5CTMMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/journals/[id].vue").then(m => m.default || m)
  },
  {
    name: indexOcxbAqf0fHMeta?.name ?? "journals___ja",
    path: indexOcxbAqf0fHMeta?.path ?? "/journals",
    meta: indexOcxbAqf0fHMeta || {},
    alias: indexOcxbAqf0fHMeta?.alias || [],
    redirect: indexOcxbAqf0fHMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/journals/index.vue").then(m => m.default || m)
  },
  {
    name: indexOcxbAqf0fHMeta?.name ?? "journals___en",
    path: indexOcxbAqf0fHMeta?.path ?? "/en/journals",
    meta: indexOcxbAqf0fHMeta || {},
    alias: indexOcxbAqf0fHMeta?.alias || [],
    redirect: indexOcxbAqf0fHMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/journals/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93SJQHezlYbaMeta?.name ?? "p-id___ja",
    path: _91id_93SJQHezlYbaMeta?.path ?? "/p/:id()",
    meta: _91id_93SJQHezlYbaMeta || {},
    alias: _91id_93SJQHezlYbaMeta?.alias || [],
    redirect: _91id_93SJQHezlYbaMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/p/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93SJQHezlYbaMeta?.name ?? "p-id___en",
    path: _91id_93SJQHezlYbaMeta?.path ?? "/en/p/:id()",
    meta: _91id_93SJQHezlYbaMeta || {},
    alias: _91id_93SJQHezlYbaMeta?.alias || [],
    redirect: _91id_93SJQHezlYbaMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/p/[id].vue").then(m => m.default || m)
  },
  {
    name: indexsS8BxZX3w8Meta?.name ?? "p___ja",
    path: indexsS8BxZX3w8Meta?.path ?? "/p",
    meta: indexsS8BxZX3w8Meta || {},
    alias: indexsS8BxZX3w8Meta?.alias || [],
    redirect: indexsS8BxZX3w8Meta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/p/index.vue").then(m => m.default || m)
  },
  {
    name: indexsS8BxZX3w8Meta?.name ?? "p___en",
    path: indexsS8BxZX3w8Meta?.path ?? "/en/p",
    meta: indexsS8BxZX3w8Meta || {},
    alias: indexsS8BxZX3w8Meta?.alias || [],
    redirect: indexsS8BxZX3w8Meta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/p/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93bCnn5qOESQMeta?.name ?? "redirect-places-id___ja",
    path: _91id_93bCnn5qOESQMeta?.path ?? "/redirect/places/:id()",
    meta: _91id_93bCnn5qOESQMeta || {},
    alias: _91id_93bCnn5qOESQMeta?.alias || [],
    redirect: _91id_93bCnn5qOESQMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/redirect/places/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93bCnn5qOESQMeta?.name ?? "redirect-places-id___en",
    path: _91id_93bCnn5qOESQMeta?.path ?? "/en/redirect/places/:id()",
    meta: _91id_93bCnn5qOESQMeta || {},
    alias: _91id_93bCnn5qOESQMeta?.alias || [],
    redirect: _91id_93bCnn5qOESQMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/redirect/places/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93FLHxdamUNQMeta?.name ?? "series-id___ja",
    path: _91id_93FLHxdamUNQMeta?.path ?? "/series/:id()",
    meta: _91id_93FLHxdamUNQMeta || {},
    alias: _91id_93FLHxdamUNQMeta?.alias || [],
    redirect: _91id_93FLHxdamUNQMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/series/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93FLHxdamUNQMeta?.name ?? "series-id___en",
    path: _91id_93FLHxdamUNQMeta?.path ?? "/en/series/:id()",
    meta: _91id_93FLHxdamUNQMeta || {},
    alias: _91id_93FLHxdamUNQMeta?.alias || [],
    redirect: _91id_93FLHxdamUNQMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/series/[id].vue").then(m => m.default || m)
  },
  {
    name: indexBw9tBTNh8dMeta?.name ?? "series___ja",
    path: indexBw9tBTNh8dMeta?.path ?? "/series",
    meta: indexBw9tBTNh8dMeta || {},
    alias: indexBw9tBTNh8dMeta?.alias || [],
    redirect: indexBw9tBTNh8dMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/series/index.vue").then(m => m.default || m)
  },
  {
    name: indexBw9tBTNh8dMeta?.name ?? "series___en",
    path: indexBw9tBTNh8dMeta?.path ?? "/en/series",
    meta: indexBw9tBTNh8dMeta || {},
    alias: indexBw9tBTNh8dMeta?.alias || [],
    redirect: indexBw9tBTNh8dMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/series/index.vue").then(m => m.default || m)
  },
  {
    name: cookie_policyz56jorFfvYMeta?.name ?? "support-cookie_policy___ja",
    path: cookie_policyz56jorFfvYMeta?.path ?? "/support/cookie_policy",
    meta: cookie_policyz56jorFfvYMeta || {},
    alias: cookie_policyz56jorFfvYMeta?.alias || [],
    redirect: cookie_policyz56jorFfvYMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/support/cookie_policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_policyz56jorFfvYMeta?.name ?? "support-cookie_policy___en",
    path: cookie_policyz56jorFfvYMeta?.path ?? "/en/support/cookie_policy",
    meta: cookie_policyz56jorFfvYMeta || {},
    alias: cookie_policyz56jorFfvYMeta?.alias || [],
    redirect: cookie_policyz56jorFfvYMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/support/cookie_policy.vue").then(m => m.default || m)
  },
  {
    name: indexgoDMX1OEDYMeta?.name ?? "travels___ja",
    path: indexgoDMX1OEDYMeta?.path ?? "/travels",
    meta: indexgoDMX1OEDYMeta || {},
    alias: indexgoDMX1OEDYMeta?.alias || [],
    redirect: indexgoDMX1OEDYMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/index.vue").then(m => m.default || m)
  },
  {
    name: indexgoDMX1OEDYMeta?.name ?? "travels___en",
    path: indexgoDMX1OEDYMeta?.path ?? "/en/travels",
    meta: indexgoDMX1OEDYMeta || {},
    alias: indexgoDMX1OEDYMeta?.alias || [],
    redirect: indexgoDMX1OEDYMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/index.vue").then(m => m.default || m)
  },
  {
    name: accessBEbAenNp8jMeta?.name ?? "travels-kumano-kumanoshi-access___ja",
    path: accessBEbAenNp8jMeta?.path ?? "/travels/kumano/kumanoshi/access",
    meta: accessBEbAenNp8jMeta || {},
    alias: accessBEbAenNp8jMeta?.alias || [],
    redirect: accessBEbAenNp8jMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/access.vue").then(m => m.default || m)
  },
  {
    name: accessBEbAenNp8jMeta?.name ?? "travels-kumano-kumanoshi-access___en",
    path: accessBEbAenNp8jMeta?.path ?? "/en/travels/kumano/kumanoshi/access",
    meta: accessBEbAenNp8jMeta || {},
    alias: accessBEbAenNp8jMeta?.alias || [],
    redirect: accessBEbAenNp8jMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/access.vue").then(m => m.default || m)
  },
  {
    name: akagijoF8YjNTWpycMeta?.name ?? "travels-kumano-kumanoshi-akagijo___ja",
    path: akagijoF8YjNTWpycMeta?.path ?? "/travels/kumano/kumanoshi/akagijo",
    meta: akagijoF8YjNTWpycMeta || {},
    alias: akagijoF8YjNTWpycMeta?.alias || [],
    redirect: akagijoF8YjNTWpycMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/akagijo.vue").then(m => m.default || m)
  },
  {
    name: akagijoF8YjNTWpycMeta?.name ?? "travels-kumano-kumanoshi-akagijo___en",
    path: akagijoF8YjNTWpycMeta?.path ?? "/en/travels/kumano/kumanoshi/akagijo",
    meta: akagijoF8YjNTWpycMeta || {},
    alias: akagijoF8YjNTWpycMeta?.alias || [],
    redirect: akagijoF8YjNTWpycMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/akagijo.vue").then(m => m.default || m)
  },
  {
    name: hananoiwayaKICwpSBzqxMeta?.name ?? "travels-kumano-kumanoshi-hananoiwaya___ja",
    path: hananoiwayaKICwpSBzqxMeta?.path ?? "/travels/kumano/kumanoshi/hananoiwaya",
    meta: hananoiwayaKICwpSBzqxMeta || {},
    alias: hananoiwayaKICwpSBzqxMeta?.alias || [],
    redirect: hananoiwayaKICwpSBzqxMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/hananoiwaya.vue").then(m => m.default || m)
  },
  {
    name: hananoiwayaKICwpSBzqxMeta?.name ?? "travels-kumano-kumanoshi-hananoiwaya___en",
    path: hananoiwayaKICwpSBzqxMeta?.path ?? "/en/travels/kumano/kumanoshi/hananoiwaya",
    meta: hananoiwayaKICwpSBzqxMeta || {},
    alias: hananoiwayaKICwpSBzqxMeta?.alias || [],
    redirect: hananoiwayaKICwpSBzqxMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/hananoiwaya.vue").then(m => m.default || m)
  },
  {
    name: indexvyV43yHY5yMeta?.name ?? "travels-kumano-kumanoshi___ja",
    path: indexvyV43yHY5yMeta?.path ?? "/travels/kumano/kumanoshi",
    meta: indexvyV43yHY5yMeta || {},
    alias: indexvyV43yHY5yMeta?.alias || [],
    redirect: indexvyV43yHY5yMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/index.vue").then(m => m.default || m)
  },
  {
    name: indexvyV43yHY5yMeta?.name ?? "travels-kumano-kumanoshi___en",
    path: indexvyV43yHY5yMeta?.path ?? "/en/travels/kumano/kumanoshi",
    meta: indexvyV43yHY5yMeta || {},
    alias: indexvyV43yHY5yMeta?.alias || [],
    redirect: indexvyV43yHY5yMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/index.vue").then(m => m.default || m)
  },
  {
    name: mapSgpmRXGnx2Meta?.name ?? "travels-kumano-kumanoshi-map___ja",
    path: mapSgpmRXGnx2Meta?.path ?? "/travels/kumano/kumanoshi/map",
    meta: mapSgpmRXGnx2Meta || {},
    alias: mapSgpmRXGnx2Meta?.alias || [],
    redirect: mapSgpmRXGnx2Meta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/map.vue").then(m => m.default || m)
  },
  {
    name: mapSgpmRXGnx2Meta?.name ?? "travels-kumano-kumanoshi-map___en",
    path: mapSgpmRXGnx2Meta?.path ?? "/en/travels/kumano/kumanoshi/map",
    meta: mapSgpmRXGnx2Meta || {},
    alias: mapSgpmRXGnx2Meta?.alias || [],
    redirect: mapSgpmRXGnx2Meta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/map.vue").then(m => m.default || m)
  },
  {
    name: matsumototougeFBWrIG1uafMeta?.name ?? "travels-kumano-kumanoshi-matsumototouge___ja",
    path: matsumototougeFBWrIG1uafMeta?.path ?? "/travels/kumano/kumanoshi/matsumototouge",
    meta: matsumototougeFBWrIG1uafMeta || {},
    alias: matsumototougeFBWrIG1uafMeta?.alias || [],
    redirect: matsumototougeFBWrIG1uafMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/matsumototouge.vue").then(m => m.default || m)
  },
  {
    name: matsumototougeFBWrIG1uafMeta?.name ?? "travels-kumano-kumanoshi-matsumototouge___en",
    path: matsumototougeFBWrIG1uafMeta?.path ?? "/en/travels/kumano/kumanoshi/matsumototouge",
    meta: matsumototougeFBWrIG1uafMeta || {},
    alias: matsumototougeFBWrIG1uafMeta?.alias || [],
    redirect: matsumototougeFBWrIG1uafMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/matsumototouge.vue").then(m => m.default || m)
  },
  {
    name: onigajoLbUHqJw19MMeta?.name ?? "travels-kumano-kumanoshi-onigajo___ja",
    path: onigajoLbUHqJw19MMeta?.path ?? "/travels/kumano/kumanoshi/onigajo",
    meta: onigajoLbUHqJw19MMeta || {},
    alias: onigajoLbUHqJw19MMeta?.alias || [],
    redirect: onigajoLbUHqJw19MMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/onigajo.vue").then(m => m.default || m)
  },
  {
    name: onigajoLbUHqJw19MMeta?.name ?? "travels-kumano-kumanoshi-onigajo___en",
    path: onigajoLbUHqJw19MMeta?.path ?? "/en/travels/kumano/kumanoshi/onigajo",
    meta: onigajoLbUHqJw19MMeta || {},
    alias: onigajoLbUHqJw19MMeta?.alias || [],
    redirect: onigajoLbUHqJw19MMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/onigajo.vue").then(m => m.default || m)
  },
  {
    name: sengyogKDBFNAG1YMeta?.name ?? "travels-kumano-kumanoshi-sengyo___ja",
    path: sengyogKDBFNAG1YMeta?.path ?? "/travels/kumano/kumanoshi/sengyo",
    meta: sengyogKDBFNAG1YMeta || {},
    alias: sengyogKDBFNAG1YMeta?.alias || [],
    redirect: sengyogKDBFNAG1YMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/sengyo.vue").then(m => m.default || m)
  },
  {
    name: sengyogKDBFNAG1YMeta?.name ?? "travels-kumano-kumanoshi-sengyo___en",
    path: sengyogKDBFNAG1YMeta?.path ?? "/en/travels/kumano/kumanoshi/sengyo",
    meta: sengyogKDBFNAG1YMeta || {},
    alias: sengyogKDBFNAG1YMeta?.alias || [],
    redirect: sengyogKDBFNAG1YMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/sengyo.vue").then(m => m.default || m)
  },
  {
    name: senmaidaV0hrXZjhHKMeta?.name ?? "travels-kumano-kumanoshi-senmaida___ja",
    path: senmaidaV0hrXZjhHKMeta?.path ?? "/travels/kumano/kumanoshi/senmaida",
    meta: senmaidaV0hrXZjhHKMeta || {},
    alias: senmaidaV0hrXZjhHKMeta?.alias || [],
    redirect: senmaidaV0hrXZjhHKMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/senmaida.vue").then(m => m.default || m)
  },
  {
    name: senmaidaV0hrXZjhHKMeta?.name ?? "travels-kumano-kumanoshi-senmaida___en",
    path: senmaidaV0hrXZjhHKMeta?.path ?? "/en/travels/kumano/kumanoshi/senmaida",
    meta: senmaidaV0hrXZjhHKMeta || {},
    alias: senmaidaV0hrXZjhHKMeta?.alias || [],
    redirect: senmaidaV0hrXZjhHKMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/senmaida.vue").then(m => m.default || m)
  },
  {
    name: shichirimihamaxPW9LH7TabMeta?.name ?? "travels-kumano-kumanoshi-shichirimihama___ja",
    path: shichirimihamaxPW9LH7TabMeta?.path ?? "/travels/kumano/kumanoshi/shichirimihama",
    meta: shichirimihamaxPW9LH7TabMeta || {},
    alias: shichirimihamaxPW9LH7TabMeta?.alias || [],
    redirect: shichirimihamaxPW9LH7TabMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/shichirimihama.vue").then(m => m.default || m)
  },
  {
    name: shichirimihamaxPW9LH7TabMeta?.name ?? "travels-kumano-kumanoshi-shichirimihama___en",
    path: shichirimihamaxPW9LH7TabMeta?.path ?? "/en/travels/kumano/kumanoshi/shichirimihama",
    meta: shichirimihamaxPW9LH7TabMeta || {},
    alias: shichirimihamaxPW9LH7TabMeta?.alias || [],
    redirect: shichirimihamaxPW9LH7TabMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/shichirimihama.vue").then(m => m.default || m)
  },
  {
    name: shishiiwa4VWdONCFd1Meta?.name ?? "travels-kumano-kumanoshi-shishiiwa___ja",
    path: shishiiwa4VWdONCFd1Meta?.path ?? "/travels/kumano/kumanoshi/shishiiwa",
    meta: shishiiwa4VWdONCFd1Meta || {},
    alias: shishiiwa4VWdONCFd1Meta?.alias || [],
    redirect: shishiiwa4VWdONCFd1Meta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/shishiiwa.vue").then(m => m.default || m)
  },
  {
    name: shishiiwa4VWdONCFd1Meta?.name ?? "travels-kumano-kumanoshi-shishiiwa___en",
    path: shishiiwa4VWdONCFd1Meta?.path ?? "/en/travels/kumano/kumanoshi/shishiiwa",
    meta: shishiiwa4VWdONCFd1Meta || {},
    alias: shishiiwa4VWdONCFd1Meta?.alias || [],
    redirect: shishiiwa4VWdONCFd1Meta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/shishiiwa.vue").then(m => m.default || m)
  },
  {
    name: yunoguchionsen9BOQC8R72eMeta?.name ?? "travels-kumano-kumanoshi-yunoguchionsen___ja",
    path: yunoguchionsen9BOQC8R72eMeta?.path ?? "/travels/kumano/kumanoshi/yunoguchionsen",
    meta: yunoguchionsen9BOQC8R72eMeta || {},
    alias: yunoguchionsen9BOQC8R72eMeta?.alias || [],
    redirect: yunoguchionsen9BOQC8R72eMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/yunoguchionsen.vue").then(m => m.default || m)
  },
  {
    name: yunoguchionsen9BOQC8R72eMeta?.name ?? "travels-kumano-kumanoshi-yunoguchionsen___en",
    path: yunoguchionsen9BOQC8R72eMeta?.path ?? "/en/travels/kumano/kumanoshi/yunoguchionsen",
    meta: yunoguchionsen9BOQC8R72eMeta || {},
    alias: yunoguchionsen9BOQC8R72eMeta?.alias || [],
    redirect: yunoguchionsen9BOQC8R72eMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/kumanoshi/yunoguchionsen.vue").then(m => m.default || m)
  },
  {
    name: hayatamataishaWQoPFJ8aaoMeta?.name ?? "travels-kumano-shingu-hayatamataisha___ja",
    path: hayatamataishaWQoPFJ8aaoMeta?.path ?? "/travels/kumano/shingu/hayatamataisha",
    meta: hayatamataishaWQoPFJ8aaoMeta || {},
    alias: hayatamataishaWQoPFJ8aaoMeta?.alias || [],
    redirect: hayatamataishaWQoPFJ8aaoMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/hayatamataisha.vue").then(m => m.default || m)
  },
  {
    name: hayatamataishaWQoPFJ8aaoMeta?.name ?? "travels-kumano-shingu-hayatamataisha___en",
    path: hayatamataishaWQoPFJ8aaoMeta?.path ?? "/en/travels/kumano/shingu/hayatamataisha",
    meta: hayatamataishaWQoPFJ8aaoMeta || {},
    alias: hayatamataishaWQoPFJ8aaoMeta?.alias || [],
    redirect: hayatamataishaWQoPFJ8aaoMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/hayatamataisha.vue").then(m => m.default || m)
  },
  {
    name: indexrr9Ws4EaLpMeta?.name ?? "travels-kumano-shingu___ja",
    path: indexrr9Ws4EaLpMeta?.path ?? "/travels/kumano/shingu",
    meta: indexrr9Ws4EaLpMeta || {},
    alias: indexrr9Ws4EaLpMeta?.alias || [],
    redirect: indexrr9Ws4EaLpMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/index.vue").then(m => m.default || m)
  },
  {
    name: indexrr9Ws4EaLpMeta?.name ?? "travels-kumano-shingu___en",
    path: indexrr9Ws4EaLpMeta?.path ?? "/en/travels/kumano/shingu",
    meta: indexrr9Ws4EaLpMeta || {},
    alias: indexrr9Ws4EaLpMeta?.alias || [],
    redirect: indexrr9Ws4EaLpMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/index.vue").then(m => m.default || m)
  },
  {
    name: kamikura2uU6OjGoXBMeta?.name ?? "travels-kumano-shingu-kamikura___ja",
    path: kamikura2uU6OjGoXBMeta?.path ?? "/travels/kumano/shingu/kamikura",
    meta: kamikura2uU6OjGoXBMeta || {},
    alias: kamikura2uU6OjGoXBMeta?.alias || [],
    redirect: kamikura2uU6OjGoXBMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/kamikura.vue").then(m => m.default || m)
  },
  {
    name: kamikura2uU6OjGoXBMeta?.name ?? "travels-kumano-shingu-kamikura___en",
    path: kamikura2uU6OjGoXBMeta?.path ?? "/en/travels/kumano/shingu/kamikura",
    meta: kamikura2uU6OjGoXBMeta || {},
    alias: kamikura2uU6OjGoXBMeta?.alias || [],
    redirect: kamikura2uU6OjGoXBMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/kamikura.vue").then(m => m.default || m)
  },
  {
    name: kumanoohashiSbxCUBOEpNMeta?.name ?? "travels-kumano-shingu-kumanoohashi___ja",
    path: kumanoohashiSbxCUBOEpNMeta?.path ?? "/travels/kumano/shingu/kumanoohashi",
    meta: kumanoohashiSbxCUBOEpNMeta || {},
    alias: kumanoohashiSbxCUBOEpNMeta?.alias || [],
    redirect: kumanoohashiSbxCUBOEpNMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/kumanoohashi.vue").then(m => m.default || m)
  },
  {
    name: kumanoohashiSbxCUBOEpNMeta?.name ?? "travels-kumano-shingu-kumanoohashi___en",
    path: kumanoohashiSbxCUBOEpNMeta?.path ?? "/en/travels/kumano/shingu/kumanoohashi",
    meta: kumanoohashiSbxCUBOEpNMeta || {},
    alias: kumanoohashiSbxCUBOEpNMeta?.alias || [],
    redirect: kumanoohashiSbxCUBOEpNMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/kumanoohashi.vue").then(m => m.default || m)
  },
  {
    name: kushimoto1hthIkH0prMeta?.name ?? "travels-kumano-shingu-kushimoto___ja",
    path: kushimoto1hthIkH0prMeta?.path ?? "/travels/kumano/shingu/kushimoto",
    meta: kushimoto1hthIkH0prMeta || {},
    alias: kushimoto1hthIkH0prMeta?.alias || [],
    redirect: kushimoto1hthIkH0prMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/kushimoto.vue").then(m => m.default || m)
  },
  {
    name: kushimoto1hthIkH0prMeta?.name ?? "travels-kumano-shingu-kushimoto___en",
    path: kushimoto1hthIkH0prMeta?.path ?? "/en/travels/kumano/shingu/kushimoto",
    meta: kushimoto1hthIkH0prMeta || {},
    alias: kushimoto1hthIkH0prMeta?.alias || [],
    redirect: kushimoto1hthIkH0prMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/kushimoto.vue").then(m => m.default || m)
  },
  {
    name: mapQLAu3SCyreMeta?.name ?? "travels-kumano-shingu-map___ja",
    path: mapQLAu3SCyreMeta?.path ?? "/travels/kumano/shingu/map",
    meta: mapQLAu3SCyreMeta || {},
    alias: mapQLAu3SCyreMeta?.alias || [],
    redirect: mapQLAu3SCyreMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/map.vue").then(m => m.default || m)
  },
  {
    name: mapQLAu3SCyreMeta?.name ?? "travels-kumano-shingu-map___en",
    path: mapQLAu3SCyreMeta?.path ?? "/en/travels/kumano/shingu/map",
    meta: mapQLAu3SCyreMeta || {},
    alias: mapQLAu3SCyreMeta?.alias || [],
    redirect: mapQLAu3SCyreMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/kumano/shingu/map.vue").then(m => m.default || m)
  },
  {
    name: indexh1Ht8cwzoKMeta?.name ?? "travels-nagano-matsumoto___ja",
    path: indexh1Ht8cwzoKMeta?.path ?? "/travels/nagano/matsumoto",
    meta: indexh1Ht8cwzoKMeta || {},
    alias: indexh1Ht8cwzoKMeta?.alias || [],
    redirect: indexh1Ht8cwzoKMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/nagano/matsumoto/index.vue").then(m => m.default || m)
  },
  {
    name: indexh1Ht8cwzoKMeta?.name ?? "travels-nagano-matsumoto___en",
    path: indexh1Ht8cwzoKMeta?.path ?? "/en/travels/nagano/matsumoto",
    meta: indexh1Ht8cwzoKMeta || {},
    alias: indexh1Ht8cwzoKMeta?.alias || [],
    redirect: indexh1Ht8cwzoKMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/nagano/matsumoto/index.vue").then(m => m.default || m)
  },
  {
    name: indexgt2GR5EM6jMeta?.name ?? "travels-shikoku-kochi___ja",
    path: indexgt2GR5EM6jMeta?.path ?? "/travels/shikoku/kochi",
    meta: indexgt2GR5EM6jMeta || {},
    alias: indexgt2GR5EM6jMeta?.alias || [],
    redirect: indexgt2GR5EM6jMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/shikoku/kochi/index.vue").then(m => m.default || m)
  },
  {
    name: indexgt2GR5EM6jMeta?.name ?? "travels-shikoku-kochi___en",
    path: indexgt2GR5EM6jMeta?.path ?? "/en/travels/shikoku/kochi",
    meta: indexgt2GR5EM6jMeta || {},
    alias: indexgt2GR5EM6jMeta?.alias || [],
    redirect: indexgt2GR5EM6jMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/shikoku/kochi/index.vue").then(m => m.default || m)
  },
  {
    name: map9wFUZecgQiMeta?.name ?? "travels-shikoku-kochi-map___ja",
    path: map9wFUZecgQiMeta?.path ?? "/travels/shikoku/kochi/map",
    meta: map9wFUZecgQiMeta || {},
    alias: map9wFUZecgQiMeta?.alias || [],
    redirect: map9wFUZecgQiMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/shikoku/kochi/map.vue").then(m => m.default || m)
  },
  {
    name: map9wFUZecgQiMeta?.name ?? "travels-shikoku-kochi-map___en",
    path: map9wFUZecgQiMeta?.path ?? "/en/travels/shikoku/kochi/map",
    meta: map9wFUZecgQiMeta || {},
    alias: map9wFUZecgQiMeta?.alias || [],
    redirect: map9wFUZecgQiMeta?.redirect,
    component: () => import("/Users/eimei/Documents/projects/kuma2zen/kuma2zen-webapp/pages/travels/shikoku/kochi/map.vue").then(m => m.default || m)
  }
]