import dayjs from "dayjs";
import 'dayjs/locale/ja';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

/// DateFormatterを書きやすくするためのヘルパー関数
export function dateFormat(
  date: string | Date | number | dayjs.Dayjs | null,
  lang: string = 'ja',
) {
  return new DateFormatter(date, lang)
}

let isTimezoneInitialized = false

export class DateFormatter {
  private readonly date: dayjs.Dayjs | null;
  private readonly lang: string

  /// ウェブアプリなど起動時のタイムゾーン初期化
  static initTimezone() {
    if (isTimezoneInitialized) return

    dayjs.extend(utc)
    dayjs.extend(timezone)
    dayjs.tz.setDefault("Asia/Tokyo")
    dayjs.locale('ja')
    isTimezoneInitialized = true
  }

  constructor(
    date: string | Date | number | dayjs.Dayjs | null,
    lang: string,
  ) {
    this.date = date ? dayjs(date) : null
    this.lang = lang || 'ja'
  }

  private _format(templates: Record<string, string>): string {
    if (!this.date) return ''

    const lang = this.lang
    const template = templates[lang]
    if (!template) throw Error(`No template. lang:${lang}`)

    // tz()を呼び出さないと、dayjs.tz.setDefault("Asia/Tokyo")の設定が反映されないので注意
    return dayjs.tz(this.date)
      .locale(lang)
      .format(template)
  }

  localizedYMD(): string {
    return this._format({
      ja: "YYYY年M月D日",
      en: "MMMM D, YYYY",
    })
  }

  localizedYMDWeekday(): string {
    return this._format({
      ja: "YYYY年M月D日（ddd）",
      en: "ddd, MMMM D, YYYY",
    })
  }

  localizedYMDWeekdayHM(): string {
    return this._format({
      ja: "YYYY年M月D日（ddd）HH:mm",
      en: "ddd, MMMM D, YYYY hh:mm A",
    })
  }

  localizedYMDH(): string {
    return this._format({
      ja: "YYYY年M月D日 H時",
      en: "MMMM D, YYYY h A",
    })
  }

  localizedYMDHM(): string {
    return this._format({
      ja: "YYYY年M月D日 HH:mm",
      en: "MMMM D, YYYY hh:mm A",
    })
  }

  localizedYMDHMS(): string {
    return this._format({
      ja: "YYYY年M月D日 HH:mm:ss",
      en: "MMMM D, YYYY HH:mm:ss",
    })
  }

  localizedYM(): string {
    return this._format({
      ja: "YYYY年M月",
      en: "MMMM, YYYY",
    })
  }
}